import roleMap from './roleMap'
import unauthorized from './unauthorized'
import {getUser} from '../../utils/authToken'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

const parseUserGroup = (string) => {
  if (string === 'superadmin') {
    return {
      group: 'superadmin',
      country: null,
      supervisor: false
    }
  }

  const matched = string.match(/(\w+)\.(\w+)(?:(?:\.)(\w+))?/)

  if (matched === null) {
    return {}
  }

  /* eslint-disable-next-line no-unused-vars */
  const [input, group, country, supervisor, ...rest] = matched

  return {
    group,
    country,
    supervisor: typeof supervisor !== 'undefined'
  }
}

const readGroupConfig = ({group, supervisor}) =>
  supervisor === true
    ? roleMap[group]['supervisor']
    : roleMap[group] && roleMap[group][group]
    ? roleMap[group][group]
    : {}

const arrayIntersection = (first, second) =>
  first.filter((value) => second.indexOf(value) !== -1 || second.length === 0)

const mergeRoleConfigs = (acc, item) => {
  return {
    homepage: item.homepage,
    menuItems: {
      hiddenFields: arrayIntersection(
        acc.menuItems.hiddenFields,
        item.menuItems.hiddenFields
      )
    },
    permissions: {
      auction: uniq([...acc.permissions.auction, ...item.permissions.auction]),
      sales: uniq([...acc.permissions.sales, ...item.permissions.sales])
    },
    fieldFilters: {
      contact: uniq([...acc.fieldFilters.contact, ...item.fieldFilters.contact])
    }
  }
}

export const getUserConfig = (countryCode = '') => {
  const auth = getUser()

  if (
    auth === false ||
    typeof auth === 'undefined' ||
    typeof auth.user === 'undefined'
  ) {
    return {...unauthorized}
  }

  const {groups} = auth.user
  const supportedUserGroups = groups
    .map(parseUserGroup)
    .filter(
      (userGroup) =>
        typeof roleMap[userGroup.group] !== 'undefined' &&
        (userGroup.country === countryCode.toLowerCase() ||
          userGroup.country === 'all')
    )
  const uniqSupportedUserGroups = uniqBy(supportedUserGroups, 'group')

  const userGroupConfigs = uniqSupportedUserGroups.map(readGroupConfig)

  if (userGroupConfigs.length === 0) {
    userGroupConfigs.push({...unauthorized})
  }

  const config = userGroupConfigs.filter(obj => obj.homepage !== '/cardata/list')
    .splice(1)
    .reduce(mergeRoleConfigs, userGroupConfigs[0])

  return {
    ...config,
    hasSameId: (userId) => auth.user.id === userId,
    hasPermission: ({resource, permission}) =>
      typeof config.permissions[resource] === 'undefined'
        ? false
        : config.permissions[resource].has(permission)
  }
}

export const getUserConfigByRole = (role) => {
  const parsedGroup = parseUserGroup(role)

  return readGroupConfig(parsedGroup)
}

export default getUserConfig
