export {default as CRM} from './crm'
export {default as inspectionReports} from './inspectionReports'
export {default as dealers} from './dealers/dealers'
export {default as dealerCreate} from './dealers/dealerCreate'
export {default as dealerDetail} from './dealers/dealerDetail'
export {default as dealerEdit} from './dealers/dealerEdit'
export {default as driverCreate} from './drivers/driverCreate'
export {default as driverEdit} from './drivers/driverEdit'
export {default as driverDetail} from './drivers/driverDetail'
export {default as drivers} from './drivers/drivers'
export {default as locationManagement} from './locationManagement'
export {default as locationManager} from './locationManager'
export {default as resourceManager} from './resourceManager'
export {default as standaloneWalkin} from './crm/standaloneWalkin'
export {default as auctionsManagement} from './auctionsManagement'
export {default as documentManagement} from './documentManagement'
export {
  DocumentConfig,
  documentConfigCreate,
  documentConfigEdit
} from './documentConfig'
export {default as category} from './category/category'
// export {carDataConfig} from './carData'
export {b2bDealConfig} from './bulkUpload'
export {b2bDealConfigV2} from './bulkUpload-v2'
export {webhookConfig} from './webhook'
export {default as payslips} from './payslips/list/payslipConfig'
export {default as transitJobEdit} from './transitJobs/transitJobEdit'
export {default as transitJobCreate} from './transitJobs/transitJobCreate'
export {default as transitJobDetail} from './transitJobs/transitJobDetail'
export {default as transit} from './transitJobs/transitJob'
export {default as carOwnership} from './carOwnership/config'
export {default as transitReview} from './transitReview'
export {default as participant} from './crm/participant'
export {default as wms} from './wms'
export {default as attendanceManager} from './attendanceManager'
export {default as DDayBookings} from './dDayBookings'
export {default as tms} from './tms/tms'
