const dealerDetails = require('./dealerManagement/id')
const crmDetails = require('./CRM/id')
const bulkuploadDetails = require('./bulkUpload/id')
const carDataDetails = require('./carData/id')
const locationDetails = require('./locationManagement/id')
const payslipDetails = require('./payslips/id')
const carOwnership = require('./carOwnership/id')
const businessPartnersDetails = require('./businessPartnersDetails/id')

module.exports = {
  url: 'belimobilgue.com',
  siteCode: 'olxid',
  apiDomain: 'api.olx.co.id',
  name: 'Indonesia',
  countryCode: 'ID',
  theme: 'indonesia',
  language: {
    selected: 'en-id',
    options: [
      {value: 'en-id', label: 'EN'},
      {value: 'id', label: 'ID'}
    ]
  },
  locales: ['id', 'en-id'],
  currency: {code: 'IDR', symbol: 'Rp'},
  number: {decimalSep: '.', unitSep: ',', units: [1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD/MM/YYYY',
  phone: {length: {min: 9, max: 13}, code: 62},
  useExactPhoneNumberSearch: true,
  referralCodeType: 'input',
  googleKey:
    '577303024655-pp6va76dp2okuqro350vttuqqaatnj2h.apps.googleusercontent.com',
  googleGlobalKey:
    '1044488849625-sblfrsccuroas3977crvu9ht3lofvmae.apps.googleusercontent.com',
  // okta: {
  //   domain: 'olxgroup.okta-emea.com',
  //   clientId: '0oa83jgyay9p1wEhR0i7'
  // },
  disabledBookingSources: ['Web Lead'],
  readOnlyPaths: ['dealers'],
  dealerDetails,
  disabledPrefixFields:
    dealerDetails && dealerDetails.disabledPrefixFields
      ? dealerDetails.disabledPrefixFields
      : undefined,
  crmDetails,
  carDuplicatedCheckField: 'licensePlate',
  auctionPreviewOffset: 60,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'carOwnershipTransfer',
    'locations',
    'wms',
    'tms',
    'bulkUploadV2',
    'attendanceManager',
    'documentManagement',
    'ddayBookings',
    'carData'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: true,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: 'https://www.olx.co.id/item',
    disabled: false
  },
  showResourceManagerPage: true,
  hideFromMenu: {
    category: false
  },
  callingCode: '+62',
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  categoryId: 198,
  useGrootApi: ['make', 'model', 'year', 'variant'],
  hasLogsInContactDetail: true,
  locationNeededForAip: true,
  fetchLocationFromCountryOptions: true,
  fetchPriceQuoteFromAIP: true,
  disableOtherFreeTextInDropdown: false,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions,
  isNotificationsEnabled: false
}
